<template>
  <div>
    <v-card-text class="bold-f-s text-center pc-title">
      Checking Account You Receive Your Payroll
    </v-card-text>
    <div class="pc-container">
      <div class="pc-content">
        <div>
          <v-row class="m-0">
            <v-col cols="12">
              <v-text-field
                class="f-s-20"
                height="20px"
                type="input"
                label="Bank"
                :rules="[rules.required]"
                v-model="bankData.bankName"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="f-s-20"
                height="20px"
                type="input"
                :rules="[rules.required, rules.routingNo]"
                label="Routing Number"
                v-model="bankData.routingNo"
                v-mask="mask"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="f-s-20"
                height="20px"
                type="input"
                :rules="[rules.required, rules.accountNo, rules.number ]"
                label="Account Number"
                v-model="bankData.accountNo"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <v-card-actions>
      <v-btn
        large
        color="primary"
        width="240"
        class="m-0 f-s-18"
        @click="next">
        Next
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import { PROFILE_SECTION_LAYOUT_SETTING } from '@/js/layout-setting/layout-setting'
import { DataType, Tool } from '../../js/core'
import { OriginationApi } from '../../api/application'

const tool = new Tool()
export default {
  components: { },
  data () {
    return {
      page: DataType.PAGES.BANK,
      decisionLogic: { show: false },
      layoutSetting: PROFILE_SECTION_LAYOUT_SETTING,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      bankData: {
        bankName: 'Wells Fargo',
        routingNo: '',
        accountNo: ''
      },
      mask: '#########',
      sourceBank: {},
      personal: {},
      rules: {
        required: value => !!value || 'Required',
        routingNo: value => {
          return value.toString().length === 9 || 'Invalid Routing Number'
        },
        accountNo: value => {
          return (value.toString().length >= 4 && value.toString().length <= 17) || 'Invalid Account Number'
        },
        number: value => {
          return /^\d+$/.test(value) || 'Account # should be a digit'
        }
      }
    }
  },
  async created () {
    const _this = this
    this.$store.commit('setOverlay', true)
    _this.$store.commit('setCurrentPage', this.page)
    // TODO get bank information
    await OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'BANK'
    }, function (result) {
      _this.sourceBank = result
      for (const key in _this.bankData) {
        _this.bankData[key] = result[key]
      }
      _this.overlay = false
    }, function (res) {
    })
    this.personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))

    this.$store.commit('setOverlay', false)
  },
  methods: {
    next () {
      const _this = this
      const updateBank = {}
      for (const key in _this.bankData) {
        const value = _this.bankData[key].trim()
        if (key === 'routingNo' && value.length !== 9) {
          return false
        }
        if (key === 'accountNo' && (value.length < 4 && value.length > 17)) {
          return false
        }
        if (value !== this.sourceBank[key]) {
          updateBank[key] = this.bankData[key]
        }
      }
      if (tool.isEmpty(updateBank)) {
        const stepMessage = {
          currect: DataType.PAGES.BANK,
          next: DataType.PAGES.IBV
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.BANK.step,
          DataType.PAGES.IBV.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        _this.$router.push(DataType.PAGES.DL_IF.addressPC)
        // const category = localStorage.getItem(DataType.COOKIE_KEY.CATEGORY)
        // if (parseInt(category) === DataType.CategoryEnum.RETURNING_CUSTOMER.value) {
        //   _this.$router.push(DataType.PAGES.DL_VERIFY.addressPC)
        // } else {
        //   _this.$router.push(DataType.PAGES.DL_IF.addressPC)
        // }
      } else {
        _this.$store.commit('setOverlay', true)
        updateBank.loanId = this.sourceBank.loanId
        OriginationApi.updateOrigination({ feature: 'bank', data: updateBank }, function () {
          _this.$store.commit('setOverlay', false)
          const stepMessage = {
            currect: DataType.PAGES.BANK,
            next: DataType.PAGES.IBV
          }
          _this.$eventBus.$emit('sendStepToSloth', stepMessage)
          OriginationApi.enteredAndLeft(
            localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            DataType.PAGES.BANK.step,
            DataType.PAGES.IBV.step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          // const category = localStorage.getItem(DataType.COOKIE_KEY.CATEGORY)
          _this.$router.push(DataType.PAGES.DL_IF.addressPC)
          // if (parseInt(category) === DataType.CategoryEnum.RETURNING_CUSTOMER.value) {
          //   _this.$router.push(DataType.PAGES.DL_VERIFY.addressPC)
          // } else {
          //   _this.$router.push(DataType.PAGES.DL_IF.addressPC)
          // }
        }, function (result) {
          if (result.code === -20002) {
            _this.$router.push('/instant/notification/-100')
          }
          if (tool.isNotEmpty(result.code) && result.code === -83010300) {
            const code = ['4CEA288ECFC282EB862726283F92EB98']
            OriginationApi.updateOrigination({
              feature: 'loan-status',
              data: {
                loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
                loanStatus: DataType.LoanStatus.POSITIVE_WITHDRAWN.value,
                withdrawCode: DataType.WithdrawCodeEnum.BAD_BANK_BAD_ABA.value,
                withdrawnReasonText: DataType.WithdrawCodeEnum.BAD_BANK_BAD_ABA.text,
                denyCode: JSON.stringify(code)
              }
            }, function () {
              _this.$router.push('/instant/notification/-400')
            })
          }
        })
      }
      _this.$eventBus.$emit('closeCountDown')
    }
  }
}
</script>>
<style lang="css">
  .btn {
    margin-left: 12px;
  }

  .bank input {
    text-align: left !important;
    font-size: 16px !important;
  }
</style>
